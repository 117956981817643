import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

export const useUserStore = defineStore('user', () => {
  const isAuthenticated = ref(useLocalStorage('lbt-is-authenticated', false))
  const userData = ref(useLocalStorage('lbt-user-data'))
  const userMeta = ref(useLocalStorage('lbt-user-meta', {}))
  const userRoles = ref(useLocalStorage('lbt-user-roles', []))
  const userSession = ref(useLocalStorage('lbt-user-session', {}))
  const accessToken = ref(useLocalStorage('lbt-access-token', {}))

  const filter = ref(
    useLocalStorage('lbt-filter', {
      page: 1,
      phrase: '',
      sortBy: '',
      filterBy: {
        is_published: '',
        has_questions: '',
        has_content: ''
      },
      filterByTracks: []
    })
  )

  const setUser = (data) => {
    isAuthenticated.value = true
    userData.value = data.data
    userMeta.value = data.meta
    userRoles.value = data.data.roles.map((item) => item.name)
  }

  const setAccessToken = (data) => {
    accessToken.value = data
  }

  const setUserSession = (data) => {
    userSession.value = data
  }

  const clearUser = () => {
    isAuthenticated.value = false
    userData.value = null
    userMeta.value = null
    userRoles.value = []
    userSession.value = null
    accessToken.value = null
    filter.value = null
  }

  return {
    isAuthenticated,
    accessToken,
    userRoles,
    userSession,
    userData,
    userMeta,
    setUser,
    clearUser,
    setAccessToken,
    setUserSession,
    filter
  }
})
